import React from "react"
import Terms from "../../../../components/body/pages/en-ng/legal/terms"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

const TermsPage = () => (
  <Layout>
    <SEO 
    canonical={"https://kuda.com/en-ng/legal/terms/"}
    title="Terms & Conditions | Kuda | The Money App for Africans" 
    />
    <Terms />
  </Layout>
)

export default TermsPage
